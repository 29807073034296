<script>
import './bestList.scss'
import Layout from "@/views/layouts/main.vue";
import PageHeader from "@/components/page_header_new.vue";
import appConfig from "@/app.config.json";
import {mapState} from "vuex";
import {loggedInfoObject} from "@/state/helpers";
import axios from "axios";
import {Korean} from "flatpickr/dist/l10n/ko.js";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import {msgConfirmOptCenter, msgModalOptCenter} from "@/components/globalFunctions";

export default {
  page: {
    title: "베스트상품 상품관리",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },

  components: {
    Layout,
    PageHeader,
    flatPickr
  },

  data() {
    return {
      deviceId: "9876543210",
      title: "베스트상품 상품관리",
      marketName: "크리톡",
      todayYear: "",
      todayMonth: "",
      todayDate: "",
      index: 0,
      eventGroupList: [],
      noImg: "https://cdn1.critalk.com/static/images/no_image.png",
      selectedEVL: '',
      selectedEVLitemsInRank: [],
      selectedEVLItemsNotInRank: [],
      placeholderValue: "",
      tmpItemlist: [],
      searchvalue: "",
      url: "",
      today: "",
      limit: "",
      manager: "",
      multi: "",
      eventBadgeChkList: [],
      srchBadgeChkList: [],
      config: {
        enableTime: false,
        maxDate: "selectedDate",
        dateFormat: "Y-m-d",
        locale: Korean,
      },
      selectedDate: "",
    };
  },

  computed: {
    ...mapState(loggedInfoObject),
  },

  filters: {
    comma(val) {
      return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },

  mounted() {
    this.setNowTimes()
  },

  methods: {
    //오늘 날짜 가져오기
    setNowTimes() {
      var source = new Date()

      this.today = this.setDate(source, 0).replaceAll('-', '')
      this.eventGroupList = []
      this.eventGroupList[0] = this.setDate(source, 0)
      this.eventGroupList[1] = this.setDate(source, 1)
      this.eventGroupList[2] = this.setDate(source, 2)
      this.eventGroupList[3] = this.setDate(source, 3)
      this.eventGroupList[4] = this.setDate(source, 4)
      this.setUrl()
    },

    //날짜 formating
    leftPad(value) {
      if (value >= 10) {
        return value;
      }
      return `0${value}`
    },

    // 날짜 setting
    setDate(source, value) {

      if (value === 0) {
        this.todayYear = source.getFullYear()
        this.todayMonth = this.leftPad(source.getMonth() + 1)
        this.todayDate = this.leftPad(source.getDate())
      } else {
        var addDate = new Date()
        addDate.setDate(source.getDate() + value)
        this.todayYear = addDate.getFullYear()
        this.todayMonth = this.leftPad(addDate.getMonth() + 1)
        this.todayDate = this.leftPad(addDate.getDate())
      }
      return this.todayYear + '-' + this.todayMonth + '-' + this.todayDate
    },

    // iframe 주소 설정
    setUrl() {
      this.url = "critalk.com";
      this.today = new Date().toJSON().slice(0, 10).replaceAll("-", "");
      this.url = `https://market.critalk.com/product?market_srl=${this.logged_info.mart_srl}&env=dev&tdate=${this.today}`;
    },

    //특매그룹순서 변경때 변경할 순서값 return
    findId(srl) {
      return this.$refs[`${srl}`][0].value;
    },

    //특매그룹순서 변경
    seqModify(toprankSrl, before, after) {
      event.stopPropagation(); //부모el과 자식el이 모두 이벤트를 가지고 있을 때 자식의 이벤트가 부모에게로 전달되지 않게 하기 위한 명령어
      let strtoprankSrl = String(toprankSrl);
      let strBefore = String(before);
      // let params = {product_srl: strPsrl, before_seq: strBefore, after_seq: after}
      // console.log(params)
      //console.log(strtoprankSrl, strBefore, after)
      if (after > this.eventGroupList.length) {
        this.$bvModal.msgBoxOk("목록 갯수 내에서 순서를 정해주세요.", msgModalOptCenter);
        return;
      }
      if (after === "0") {
        this.$bvModal.msgBoxOk("0을 제외한 숫자로 정해주세요.", msgModalOptCenter);
        return;
      }

      this.$bvModal.msgBoxConfirm("해당 특매그룹의 순서를 변경하시겠습니까?", msgConfirmOptCenter)
          .then((sel) => {
            if (sel) {
              let url = `/api/web/market/product/event/group/seq/v2`
              let param = {
                toprank_event_srl: strtoprankSrl,
                before_seq: strBefore,
                after_seq: after
              }

              axios.put(url, param)
                  .then((result) => {
                    //console.log(result)
                    if (result.data.status === "success") {
                      this.getEventGroupList();
                      //this.$refs.iframe.contentWindow.location.replace(this.url);
                    }
                  })
                  .catch(
                      error => console.log(error)
                  )
            }
          })
    },

    // 행사 목록 상세 설정
    setEventListDetail(evl) {
      this.selectedEVL = evl;
      console.log(this.selectedEVL)
      this.placeholderValue = "";
      this.selectedDate = evl
      this.selectedEVLItemsNotInRank = []

      let url = `/api/best/${this.logged_info.biznum}?gname=${this.selectedEVL}`

      axios.get(url)
          .then((res) => {
            console.log(res.data)
            this.selectedEVLitemsInRank = res.data;

            for (let i = 0; i < this.selectedEVLitemsInRank.length; i++) {
              this.selectedEVLitemsInRank[i].tmp_limit = this.selectedEVLitemsInRank[i].badge_limit === "Y";
              this.selectedEVLitemsInRank[i].tmp_manager = this.selectedEVLitemsInRank[i].badge_manager === "Y";
              this.selectedEVLitemsInRank[i].tmp_multi = this.selectedEVLitemsInRank[i].badge_multi === "Y";
            }

            this.selectedEVLitemsInRank.sort(function (a, b) {
              return a["seq"] - b["seq"]
            })

          })
          .catch(
              error => console.log(error)
          )
    },

    // 이전 목록 복사하기
    copyPreviousList() {
      this.$bvModal.msgBoxConfirm(this.selectedDate + '의 상품을 가져오시겠습니까?\n작업중이던 목록은 삭제됩니다.', msgConfirmOptCenter)
          .then((sel) => {
            if (sel) {
              let url = `/api/best/copy/${this.logged_info.biznum}`
              let param = {
                source_gname: this.selectedDate,
                target_gname: this.selectedEVL
              }

              axios.post(url, param)
                  .then(() => {
                    this.setEventListDetail(this.selectedEVL);
                  })
                  .catch(
                      error => console.log(error)
                  )
            }
          })
    },

    // 순위에 상품 추가
    putItemInRank(barcode) {
      this.$bvModal.msgBoxConfirm("해당 상품을 베스트 상품 목록에 넣으시겠습니까?", msgConfirmOptCenter)
          .then((sel) => {
            if (sel) {
              let url = `/api/best/${this.logged_info.biznum}`

              let param = {
                barcode: barcode,
                gname: this.selectedEVL
              }

              axios.post(url, param)
                  .then(() => {
                    this.setEventListDetail(this.selectedEVL);

                    for (let i = 0; i < this.selectedEVLItemsNotInRank.length; i++) {
                      if (this.selectedEVLItemsNotInRank[i].barcode === barcode) {
                        this.selectedEVLItemsNotInRank.splice(i, 1)
                      }
                    }
                  })
                  .catch(
                      error => console.log(error)
                  );
            }
          })
    },


    //특매그룹순서 변경
    seqModifyInSEVL(product_srl, before, after) {
      let strproduct_srl = String(product_srl);
      let strBefore = String(before);
      // let params = {product_srl: strPsrl, before_seq: strBefore, after_seq: after}
      // console.log(params)
      //console.log("seqModifyInSEVL" + strtoprankSrl, strBefore, after)
      if (after > this.selectedEVLitemsInRank.length) {
        this.$bvModal.msgBoxOk("베스트 상품에 등록한 상품 갯수 내에서 순서를 정해주세요.", msgModalOptCenter);
        return;
      }

      if (after === "0") {
        this.$bvModal.msgBoxOk("0을 제외한 숫자로 정해주세요.", msgModalOptCenter);
        return;
      }

      this.$bvModal.msgBoxConfirm("해당 상품의 순서를 변경하시겠습니까?", msgConfirmOptCenter)
          .then((sel) => {
            if (sel) {
              let url = `/api/best/seq`
              let param = {
                best_product_srl: strproduct_srl,
                before_seq: strBefore,
                after_seq: after
              }

              axios.put(url, param)
                  .then((res) => {
                    if (res.data.status === "success") {
                      this.setEventListDetail(this.selectedEVL);
                    } else {
                      this.$bvModal.msgBoxOk("해당 상품의 순서를 변경하지 못했습니다.\n다시 시도해 주세요.", msgModalOptCenter)
                    }
                  })
                  .catch(
                      error => console.log(error)
                  );
            }
          })
    },

    // 상품 삭제
    deleteTheProduct(product_srl) {
      this.$bvModal.msgBoxConfirm("해당 상품을 베스트상품 목록에서 삭제하시겠습니까?", msgConfirmOptCenter)
          .then((sel) => {
            if (sel) {
              let url = `/api/best/${this.logged_info.biznum}`
              let param = {
                data: {
                  best_product_srl: product_srl
                }
              }

              axios.delete(url, param)
                  .then((res) => {
                    if (res.data.status === "success") {
                      this.setEventListDetail(this.selectedEVL);
                    } else {
                      this.$bvModal.msgBoxOk("해당 상품을 TOP20목록에서 삭제하지 못했습니다.\n다시 시도해 주세요.", msgModalOptCenter)
                    }
                  })
                  .catch(
                      error => console.log(error)
                  )
            }
          })
    },

    // 다른 컴포넌트로 전달 방지
    noBubbling() {
      event.stopPropagation();
    },

    // 상품 검색
    searchProduct() {
      console.log(this.$refs.searchKeyword.value);
      if (this.$refs.searchKeyword.value === "") {
        this.selectedEVLItemsNotInRank = [];
      } else {
        let url = `/api/best/search/${this.logged_info.biznum}?q=${this.$refs.searchKeyword.value}&gname=${this.selectedEVL}`

        axios.get(url)
            .then((res) => {
              console.log(res.data)
              this.selectedEVLItemsNotInRank = res.data;

              for (let i = 0; i < this.selectedEVLItemsNotInRank.length; i++) {
                this.selectedEVLItemsNotInRank[i].tmp_limit = this.selectedEVLItemsNotInRank[i].badge_limit === "Y";
                this.selectedEVLItemsNotInRank[i].tmp_manager = this.selectedEVLItemsNotInRank[i].badge_manager === "Y";
                this.selectedEVLItemsNotInRank[i].tmp_multi = this.selectedEVLItemsNotInRank[i].badge_multi === "Y";
              }
            })
            .catch(
                error => console.log(error)
            );
      }
    },

    //뱃지 수정 및 한줄글 수정
    editItem(val, wherefrom) {
      event.stopPropagation(); //부모el과 자식el이 모두 이벤트를 가지고 있을 때 자식의 이벤트가 부모에게로 전달되지 않게 하기 위한 명령어

      let url = `/api/openmarket/product/market/${this.logged_info.mart_srl}/9876543210`;

      let param = {
        product_srl: val.mproduct_srl,
        comment: val.comment,
        badge_limit: this.changeParam("limit", val.tmp_limit),
        badge_manager: this.changeParam("manager", val.tmp_manager),
        badge_multi: this.changeParam("multi", val.tmp_multi),
      };

      console.log("url, param", url, param);

      axios.put(url, param)
          .then((res) => {
            console.log(res.data);

            if (res.data.status === "success") {
              if (wherefrom === "ranked") {
                for (let i = 0; i < this.selectedEVLitemsInRank.length; i++) {
                  if (this.selectedEVLitemsInRank[i].mproduct_srl === val.mproduct_srl) {
                    this.selectedEVLitemsInRank[i].comment = val.comment;
                    this.selectedEVLitemsInRank[i].badge_limit = this.changeParam("limit", val.tmp_limit);
                    this.selectedEVLitemsInRank[i].badge_manager = this.changeParam("manager", val.tmp_manager);
                    this.selectedEVLitemsInRank[i].badge_multi = this.changeParam("multi", val.tmp_multi);
                  }
                }
              } else if (wherefrom === "unranked") {
                for (let ind = 0; ind < this.selectedEVLItemsNotInRank.length; ind++) {
                  if (this.selectedEVLItemsNotInRank[ind].mproduct_srl === val.mproduct_srl) {
                    this.selectedEVLItemsNotInRank[ind].comment = val.comment;
                    this.selectedEVLItemsNotInRank[ind].badge_limit = this.changeParam("limit", val.tmp_limit);
                    this.selectedEVLItemsNotInRank[ind].badge_manager = this.changeParam("manager", val.tmp_manager);
                    this.selectedEVLItemsNotInRank[ind].badge_multi = this.changeParam("multi", val.tmp_multi);
                  }
                }
              }

              this.setEventListDetail(this.selectedEVL)
              //this.setEventlistDetail(this.selectedEVL)
              //this.$refs.iframe.contentWindow.location.replace(this.url);
            } else {
              this.$bvModal.msgBoxOk("상품 정보 변경 실패하였습니다.", msgModalOptCenter)
            }
          })
          .catch(
              (err) => console.log(err)
          );
    },

    // 변수 변환기
    changeParam(chk, val) {
      switch (chk) {
        case "limit": {
          if (val === true) {
            this.limit = "Y";
          } else {
            this.limit = "N";
          }

          return this.limit;
        }

        case "manager": {
          if (val === true) {
            this.manager = "Y";
          } else {
            this.manager = "N";
          }

          return this.manager;
        }

        case "multi": {
          if (val === true) {
            this.multi = "Y";
          } else {
            this.multi = "N";
          }

          return this.multi;
        }
      }
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title"/>
    <div class="row">
      <!-- 기본정보 영역 -->
      <div class="col-md-3">
        <div class="card">
          <div class="card-header" style="padding: 20px;">
            <label class="mb-0" style="font-size: 1.2em; font-weight: bold">
              베스트상품 그룹
            </label>
          </div>

          <div class="card-body" style="padding: 20px 20px 0 20px; box-sizing: border-box">
            <!-- 베스트상품 날짜별 그룸 -->

            <!-- 특매그룹목록 -->
            <div v-for="(evl, index) in eventGroupList" :key="index" style="display: flex; justify-content: center">

              <!-- 기본 카드 (선택 안됨 )-->
              <div class="card" style="display: flex; width: 100%; justify-content: center; padding-left: 20px; box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2); border-radius: 0.25rem; height: 80px;" v-if="index < 5 && selectedEVL !== evl" @click="setEventListDetail(evl)">
                  <label class="form-check-label" style="font-size: 1.2em; font-weight: bold;">
                    [{{ evl }} 베스트상품]
                  </label>
              </div>

              <!--선택된 카드-->
              <div class="card" style="display: flex; box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2); border-radius: 0.25rem; background: #74788d; width: 100%; justify-content: center; padding-left: 20px; height: 80px;" v-if="index < 5 && selectedEVL === evl" @click="setEventListDetail(evl)">
                  <label class="form-check-label" style="font-size: 1.2em; font-weight: bold;color: white">
                    [{{ evl }} 베스트상품]
                  </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end card -->
      <!-- 기본정보 영역 END-->
      <!-- 상품순서 -->
      <div class="col-md-9">
        <!-- 순서가 정해진 상품목록 -->

        <div class="card">
          <div class="card-header">
            <div style="display: flex; justify-content: space-between; align-items: center"
                 v-if="this.selectedEVL !== ''">
              <div class="col-md-9">
                <label style="
                margin-bottom: 0 !important;
                margin-left: 10px;
                font-size: 1.3em;
                font-weight: bold;
              ">
                  {{ this.selectedEVL }} - 베스트 상품목록
                </label>
              </div>
              <div class="col-md-3">
                <div style="display: inline-flex; vertical-align: middle; float: right">
                  <flat-pickr class="form-control" value="this.selectedDate" v-model="selectedDate" :config="config"
                              style="border-radius: 0.25rem 0 0 0.25rem"/>
                  <button class="btn btn-primary"
                          style="display: inline-flex; width: 28%; align-content: center; border-radius: 0 0.25rem 0.25rem 0"
                          type="button" id="button-copy" @click="copyPreviousList">
                    복사
                  </button>
                </div>
              </div>
            </div>

            <label style="margin-bottom: 0 !important; padding-left: 10px; font-size: 1.3em;font-weight: bold;" v-if="this.selectedEVL === ''">
              좌측에서 수정할 그룹을 선택해주세요.
            </label>

          </div>
          <div class="card-body" style="padding: 20px 20px 0 8px;">
            <div v-if="selectedEVL === '' && selectedEVLitemsInRank.length === 0" style="margin-bottom: 200px"/>
            <div v-if="selectedEVL !== '' && selectedEVLitemsInRank.length === 0">
              <div style="margin-left: 12px">
                <div class="card" style="display: flex;align-items: center;border: none; box-shadow: none;border-radius: 0.25rem; height: 200px;">
                  <div class="card-body" style="margin: 0; display: flex; justify-content: center; align-items: center">
                    해당 날짜에 선택된 베스트 상품이 없습니다.
                  </div>
                </div>
              </div>
            </div>
            <div class="">
              <div class="row col-12" style="margin-right: 0; margin-left: 0"
                   v-if="selectedEVL !== '' && selectedEVLitemsInRank.length > 0">
                <!-- 베스트상품목록 -->
                <div class="col-xl-3 col-lg-3 col-md-3" style="display: inline; padding: 0"
                     v-for="sevl in selectedEVLitemsInRank" :key="sevl.seq">
                  <div style="margin-left: 12px">
                    <div class="card" style="align-items: center;box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);border-radius: 0.25rem;">
                      <div class="card-body" style="padding: 0.75rem; width: 100%">
                        <div class="flex-shrink-0 pdImgWrap" :class="{isSoldOut : sevl.soldout === 'Y'}">
                          <img v-if="sevl.img_path.length > 0" :src="`${sevl.img_path}`" :class="{grayFilter : sevl.soldout === 'Y'}" style=" width: 100%; height: 100%; border-radius: 0.25rem; object-fit: cover;"/>
                          <img v-if="sevl.img_path.length === 0" :src="`${noImg}`" style="
                              width: 130px;
                              height: 130px;
                              border-radius: 0.25rem;
                              object-fit: cover;
                            "
                          />
                        </div>
                        <hr/>
                        <div class="mb-1">
                          <div v-if="sevl.badge_limit == 'Y' ||sevl.badge_manager == 'Y' ||sevl.badge_multi == 'Y'">
                            <img v-if="sevl.badge_limit == 'Y'" :src="require('@/assets/mcimages/marker_limit@3x.png')" style="margin-right: 4px; height: 16px"/>
                            <img v-if="sevl.badge_manager == 'Y'" :src="require('@/assets/mcimages/marker_best@3x.png')" style="margin-right: 4px; height: 16px"/>
                            <img v-if="sevl.badge_multi == 'Y'" :src="require('@/assets/mcimages/marker_not_sale@3x.png')" style="height: 16px"/>
                            <br/>
                          </div>
                          <label class="form-check-label ellipsis" style="font-weight: bold">
                            {{ sevl.product_name.replaceAll("_", " ") }}
                          </label><br/>
                          <label class="form-check-label ellipsis" v-if="sevl.comment.length != 0">
                            {{ sevl.comment }}
                          </label><br/>
                          <label class="form-check-label" style="
                              font-size: 1em;
                              color: #a0a0a0;
                              margin-bottom: 6px;
                            ">
                            {{ sevl.barcode }}
                          </label><br/>
                          <label class="form-check-label" style="
                              font-size: 1.1em;
                              color: black;
                              font-weight: bold;
                              margin-right: 4px;
                            ">
                            {{ sevl.saleprice | comma }}원</label>
                          <label class="form-check-label" style="
                              font-size: 0.9em;
                              text-decoration: line-through;
                              color: #a0a0a0;
                              margin-right: 4px;
                            " v-if="sevl.percent != 0">
                            {{ sevl.price | comma }}원
                          </label>
                          <label class="form-check-label" style="font-size: 0.9em; color: red" v-if="sevl.percent != 0">
                            {{ sevl.percent }}%
                          </label><br/>
                        </div>

                        <div class="row m-0 p-0 mb-2" style="
                            display: flex;
                            justify-content: space-between;
                            width: 100%;
                            align-items: center;
                          ">
                          <div class="p-0 col-4" style="
                              margin-top: 4px;
                              position: relative;
                              display: flex;
                              justify-content: space-between;
                              width: 100%;
                              align-items: center;
                            ">
                            <input style="
                                height: 30px;
                                text-align: center;
                                display: inline;
                                vertical-align: middle;
                              " class="form-control" :placeholder="`${sevl.seq}`" onkeypress="this.value=this.value.replace(/[^0-9]/g,'')" :value="placeholderValue" :ref="`ordering${sevl.best_product_srl}`"/>
                            <span class="input-group-addon col-4" style="font-size: 12px; text-align: center">
                              번째로
                            </span>
                            <span class="button" style="display: inline; vertical-align: middle">
                              <b-button class="col-4" style="
                                  height: 30px;
                                  text-align: center;
                                  display: inline;
                                  vertical-align: middle;
                                  align-self: center;
                                " @click="seqModifyInSEVL(sevl.best_product_srl,sevl.seq, findId(`ordering${sevl.best_product_srl}`))">
                                변경
                              </b-button>
                            </span>
                          </div>
                        </div>
                        <div>
                          <b-button variant="light" style="
                              width: 100%;
                              border-radius: 0 0.25rem 0.25rem 0;
                            " @click="deleteTheProduct(sevl.best_product_srl)">
                            삭제
                          </b-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 선택되지 않은 상품목록 -->
        <div class="card">
          <div class="card-header">
            <div class="row" style="display: flex; align-items: center">
              <div class="col-md-6" style="margin-bottom: 0 !important; padding-left: 20px">
                <label style="font-size: 1.3em;font-weight: bold;display: inline-flex;margin-bottom: 0 !important;">
                  베스트 상품목록 이외의 상품
                </label>
              </div>
              <div class="col-md-6">
                <div class="col-md-11 input-group" style="display: inline-flex; vertical-align: middle; float: right">
                  <input type="text" class="form-control" style="display: inline-flex; width: 30%" placeholder="상품명 또는 바코드를 입력해주세요." aria-label="search result" aria-describedby="button-search" :value="searchvalue" ref="searchKeyword" @keypress.enter="searchProduct"/>
                  <button class="btn btn-primary" style="display: inline-flex" type="button" id="button-search" @click="searchProduct">
                    검색
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body" style="padding: 20px 20px 0">
            <div v-if="selectedEVL === '' && selectedEVLItemsNotInRank.length === 0" style="margin-bottom: 200px"/>
            <div v-if="selectedEVL !== '' && selectedEVLItemsNotInRank.length === 0">
              <div class="card" style="display: flex;align-items: center;border-radius: 0.25rem; border: none; box-shadow: none">
                <div class="card-body" style="height: 200px; display: flex; justify-content: center; align-items: center;">
                  제품명이나 바코드로 검색해주세요.
                </div>
              </div>
            </div>
            <div class="row col-12" style="width: auto;" v-if="selectedEVL !== '' && selectedEVLItemsNotInRank.length > 0">
              <!-- 특매그룹목록 -->
              <div class="col-xl-6 col-lg-6 col-md-12" style="display: inline-block;" v-for="(nsevl, index) in selectedEVLItemsNotInRank" :key="index">
                <b-card no-body class="overflow-hidden">
                  <div class="card-body" style="padding: 8px" @click="putItemInRank(nsevl.barcode)">
                    <div class="d-flex align-items-center">
                      <div class="flex-shrink-0">
                        <img v-if="nsevl.img_path.length > 0" :src="`${nsevl.img_path}`" style="
                            width: 100px;
                            height: 100px;
                            border-radius: 0.25rem;
                            object-fit: cover;
                          "/>
                        <img v-if="nsevl.img_path.length === 0" :src="`${noImg}`" style="
                            width: 100px;
                            height: 100px;
                            border-radius: 0.25rem;
                            object-fit: cover;
                          "/>
                      </div>

                      <table style="width: 100%">
                        <tr>
                          <td style="padding-left: 1em; vertical-align: top">
                            <!--// 중앙-->
                            <div style="font-size: 14px; display: inline">
                              <div v-if=" nsevl.badge_limit == 'Y' ||  nsevl.badge_manager == 'Y' ||  nsevl.badge_multi == 'Y'">
                                <img v-if="nsevl.badge_limit == 'Y'" :src="require('@/assets/mcimages/marker_limit@3x.png')" style="margin-right: 4px; height: 16px"/>
                                <img v-if="nsevl.badge_manager == 'Y'" :src="require('@/assets/mcimages/marker_best@3x.png')" style="margin-right: 4px; height: 16px"/>
                                <img v-if="nsevl.badge_multi == 'Y'" :src="require('@/assets/mcimages/marker_not_sale@3x.png')" style="height: 16px"/>
                                <br/>
                              </div>
                              <label class="form-check-label" style="font-weight: bold">
                                {{nsevl.doha_pname.replaceAll("_", " ")}}
                              </label><br/>
                              <label class="form-check-label" style="font-size: 1em">
                                {{ nsevl.comment }}
                              </label><br/>
                              <label class="form-check-label" style="
                                  font-size: 1em;
                                  color: #a0a0a0;
                                  margin-bottom: 6px;
                                ">
                                {{ nsevl.barcode }}
                              </label><br/>
                              <label class="form-check-label" style="
                                  font-size: 1.1em;
                                  color: black;
                                  font-weight: bold;
                                  margin-right: 4px;
                                ">
                                {{ nsevl.saleprice | comma }}원
                              </label>
                              <label class="form-check-label" style="
                                  font-size: 0.9em;
                                  text-decoration: line-through;
                                  color: #a0a0a0;
                                  margin-right: 4px;
                                " v-if="nsevl.percent != 0">
                                {{ nsevl.price | comma }}원
                              </label>
                              <label class="form-check-label" style="font-size: 0.9em; color: red" v-if="nsevl.percent != 0">
                                {{ nsevl.percent }}%
                              </label><br/>
                              <small class="text-muted" style="font-size: 14px"/>
                            </div>
                          </td>

                          <td style="width: 1em; text-align: right">
                            <!--// 우측-->
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </b-card>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 상품순서 END -->
      <!-- 미리보기 iframe -->
      <!--      <div class="col-md-3">
              <div class="card">
                <div class="card-body">
                  <iframe
                    style="width: 100%; height: 900px"
                    :src="`${url}`"
                    ref="iframe"
                  >
                  </iframe>
                </div>
              </div>
            </div>-->
    </div>
  </Layout>
</template>

<style scoped>
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-inline-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>

<style>
span.button > button.btn {
  padding: 0 0;
  width: 3rem;
}
</style>
